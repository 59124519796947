<template>
  <div>
    <span
      v-if="!user"
    >—</span>

    <v-tooltip
      v-else
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          class="avatar mr-2"
          size="24"
        >
          <v-img
            v-if="user.avatar_url"
            :src="user.avatar_url"
            :alt="user.name.charAt(0)"
          ></v-img>
          <span
            v-else
            class="font-weight-light text-uppercase secondary--text font-weight-regular"
          >{{ user.name.charAt(0) }}</span>
        </v-avatar>

        <span
          v-bind="attrs"
          v-on="on"
        >{{ user.users_id === userData.users_id ? $t('me') : user.name }}</span>
      </template>

      {{ user.email }}
    </v-tooltip>
  </div>
</template>

<style scoped>
  .avatar {
    background-color: var(--v-primary-base) !important;
  }
</style>

<script>

import { mapState } from 'vuex'

export default {
  name: "UserInfoColumn",

  props: {
    user: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    ...mapState({
      userData: state => state.core.auth.userData,
    }),
  },
}

</script>
