var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"mb-5 mt-n8 align-center d-flex flex-row pa-1",attrs:{"color":"appBackground darken-1","rounded":"pill"}},[_c('v-menu',{ref:"menuRef",attrs:{"close-on-content-click":false,"min-width":"250","max-width":"300","absolute":"","right":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"order-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.clearSelection($event)}}},[_c('v-icon',{attrs:{"color":"defaultIconColor"}},[_vm._v("mdi-close")])],1),_c('span',{staticClass:"order-2"},[_vm._v(" "+_vm._s(_vm.count)+" "+_vm._s(_vm.$t('selected'))+" ")]),_c('span',_vm._g({staticClass:"order-3 ml-5"},menu),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.setActionType('copyAction')}}},'v-btn',attrs,false),tooltip),[_c('v-icon',{attrs:{"color":"defaultIconColor"}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('copy')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.setActionType('moveAction')}}},'v-btn',attrs,false),tooltip),[_c('v-icon',{attrs:{"color":"defaultIconColor"}},[_vm._v("mdi-folder-move-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('move')))])])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"order-4",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeAction($event)}}},tooltip),[_c('v-icon',{attrs:{"color":"defaultIconColor"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('remove')))])])]}}])},[_c('NavigationModal',{ref:"navigationModal",attrs:{"node":_vm.selectedItems && _vm.selectedItems.length > 0 ? _vm.selectedItems[0] : undefined,"submit-title":_vm.actionType === 'copyAction' ? _vm.$t('copy') : _vm.$t('move'),"selected-items":_vm.selectedItems},on:{"chosen-directory":function($event){return _vm.callActionMethod(_vm.actionType, $event)}}})],1),_c('DefaultModal',{ref:"modalRemove",attrs:{"title":_vm.$t('remove'),"submit-button-text":"remove","deleteModal":""},on:{"submit":_vm.removeExecute},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',{staticClass:"text-body-1"},[_c('v-row',[_c('p',{staticClass:"pre-line"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Files:")]),_vm._v(" "+_vm._s(_vm.filesListString))])])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }