<template>

  <NavigationContainer
    :breadcrumbsItems="formattedBreadcrumbs"
    show-upload-button
    @search-value-changed="searchValueChanged"
    @cleared-search="fetchData"
    @fileCreated="fetchData($route.params.parentId)"
  >

    <template slot="content">
      <v-container
        v-if="loading || entries.length === 0"
        class="loading-container"
      >
        <v-row
          class="fill-height"
          align-content="center"
          justify="center"
        >
          <v-col
            class="text-subtitle-1 text-center"
            cols="12"
          >
            <span v-if="loading">{{ $t('retrieving_files') }}</span>

            <span v-else>{{ $t('empty_folder') }}</span>
          </v-col>

          <v-col
            v-if="loading"
            cols="6"
          >
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <template v-else>
        <NavigationGridView
          v-if="contentPersonalPreferences.navigationViewMode === 'grid'"
          :loading="loading"
          :entries="entries"
          @enter-folder="navigateTo"
        />

        <NavigationListView
          v-else-if="contentPersonalPreferences.navigationViewMode === 'list'"
          :loading="loading"
          :entries="entries"
          :users="users"
          @enter-folder="navigateTo"
        />
      </template>
    </template>

  </NavigationContainer>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .loading-container {
    height: 400px;
  }
</style>

<script>

import { mapState, mapActions } from 'vuex'
import router from "@/router"

import NavigationContainer from '@/components/content/NavigationContainer.vue'
import NavigationGridView from '@/components/content/navigation/grid/NavigationGridView.vue'
import NavigationListView from '@/components/content/navigation/list/NavigationListView.vue'

import EntryActions from '@/store/content/entry/actions-types'
import UserActions from '@/store/core/user/actions-types'

export default {
  name: "Navigation",

  components: {
    NavigationContainer,
    NavigationGridView,
    NavigationListView,
  },

  watch: {
    '$route.params.parentId': {
      handler: function(parentId) {
        this.fetchData(parentId)
      },
      deep: true,
      immediate: true,
    }
  },

  data() {
    return {
      loading: true,
      orderBy: {
        name: null,
        updated_at: null,
      },
      users: [],
      dragInternal: false,
      draggedList: [],
      droppedNode: null,
    }
  },

  computed: {
    ...mapState({
      breadcrumb: state => state.content.entry.breadcrumb,
      entries: state => state.content.entry.entries,
      contentPersonalPreferences: state => state.app.contentPersonalPreferences,
    }),

    formattedBreadcrumbs() {
      let formatted = [
        {
          prependIcon: 'mdi-home-outline',
          iconColor: 'primary',
          to: {
            name: 'ContentHome',
            params: { appId: this.$route.params.appId }
          },
          link: true,
          disabled: false,
          ripple: false,
          tooltipText: "Home",
        },
      ]

      const reversed = [].concat(this.breadcrumb).reverse()

      reversed.forEach(element => {
        formatted.push({
          to: {
            name: 'ContentNavigation',
            params: { parentId: element.node_id }
          },
          text: element.name,
          ripple: false
        })
      })

      return formatted
    },
  },

  methods: {
    ...mapActions('content/entry', [
      EntryActions.LIST_SUBFOLDER,
      EntryActions.SEARCH_BY_TERM
    ]),

    ...mapActions('core/user', [
      UserActions.GET_ALL_USERS,
    ]),

    fetchData(parentId) {
      this.loading = true

      const usersOptions = {
        'filter[include_foreign]': true,
        'limit': 9999,
      }

      const usersPromise = this[UserActions.GET_ALL_USERS](usersOptions)
      usersPromise.then((result) => {
        this.users = [...result.items]
      })

      const entriesPromise = this[EntryActions.LIST_SUBFOLDER]({parentId})
      
      Promise.all([usersPromise, entriesPromise])
        .finally(() => this.loading = false)
    },

    navigateTo(item) {
      router.push({
        name: 'ContentNavigation',
        params: { parentId: item.node_id }
      })
    },

    searchValueChanged({term, baseNodeId}, showBreadcrumb = false) {
      this.loading = true

      const params = {
        term,
        'filters[base_node_id]': baseNodeId
      }

      this[EntryActions.SEARCH_BY_TERM]([params, showBreadcrumb])
        .finally(() => { this.loading = false })
    },
  },

  updated() {
    if(this.breadcrumb[0].name)
      document.title = this.breadcrumb[0].name + " | Jamworks 2.0"
  },

  beforeDestroy() {
    document.title = "Jamworks 2.0"
  }
}

</script>
