<template>
  <v-sheet
    class="mb-5 mt-n8 align-center d-flex flex-row pa-1"
    color="appBackground darken-1"
    rounded="pill"
  >
    <v-menu
      :close-on-content-click="false"
      ref="menuRef"
      min-width="250"
      max-width="300"
      absolute
      right
      offset-x
      offset-y
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-btn icon @click.stop="clearSelection" class="order-1">
          <v-icon color="defaultIconColor">mdi-close</v-icon>
        </v-btn>

        <span class="order-2">
          {{ count }} {{ $t('selected') }}
        </span>

        <span v-on="menu" class="order-3 ml-5">
          <v-tooltip top>
            <template v-slot:activator="{on: tooltip}">
              <v-btn icon @click="setActionType('copyAction')" v-on="tooltip" v-bind="attrs">
                <v-icon color="defaultIconColor">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('copy') }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{on: tooltip}">
              <v-btn icon @click="setActionType('moveAction')" v-on="tooltip" v-bind="attrs">
                <v-icon color="defaultIconColor">mdi-folder-move-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('move') }}</span>
          </v-tooltip>
        </span>

        <v-tooltip top>
          <template v-slot:activator="{on: tooltip}">
            <v-btn icon @click.stop="removeAction($event)" v-on="tooltip" class="order-4">
              <v-icon color="defaultIconColor">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('remove') }}</span>
        </v-tooltip>
      </template>

      <NavigationModal
        :node="selectedItems && selectedItems.length > 0 ? selectedItems[0] : undefined"
        :submit-title="actionType === 'copyAction' ? $t('copy') : $t('move')"
        ref="navigationModal"
        :selected-items="selectedItems"
        @chosen-directory="callActionMethod(actionType, $event)"
      />
    </v-menu>

    <DefaultModal
      :title="$t('remove')"
      ref="modalRemove"
      @submit="removeExecute"
      submit-button-text="remove"
      deleteModal
    >
      <template v-slot:content>
        <v-col class="text-body-1">
          <v-row>
            <p class="pre-line"><span class="font-weight-bold">Files:</span> {{ filesListString }}</p>
          </v-row>
        </v-col>
      </template>
    </DefaultModal>
  </v-sheet>
</template>

<script>

import { mapActions } from 'vuex'
import ContentActions from '@/store/content/actions/actions-types'
import NavigationModal from '@/views/content/navigation/NavigationModal.vue'
import AppActions from '@/store/app/actions-types'
import DefaultModal from '@/components/DefaultModal.vue'

export default {
  name: "SelectedItemsBar",

  components: {
    NavigationModal,
    DefaultModal
  },

  data: () => ({
    actionType: null,
  }),

  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    parentId: {
      type: String,
      default: null
    }
  },

  computed: {
    count() {
      return this.selectedItems.length;
    },

    filesListString() {
      return this.selectedItems.map((e) => e.entry_fs_id).join(',\n')
    }
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE,
    ]),

    ...mapActions('content/actions', [
      ContentActions.REMOVE,
      ContentActions.MOVE,
      ContentActions.MOVE_ENTRIES,
      ContentActions.COPY,
    ]),

    setActionType(type) {
      this.actionType = type
    },

    clearSelection() {
      this.$emit("clear-selection")
    },

    callActionMethod(actionType, destinationNodeId) {
      const parentId = this.$route.params.parentId ?? null

      this.clearSelection()
      this.$refs.navigationModal.closeModal()
      this.$refs.menuRef.isActive = false

      const ACTION =
        actionType === 'copyAction'
        ? ContentActions.COPY :
        actionType === 'moveAction'
        ? ContentActions.MOVE : null

      if (!ACTION) {
        return
      }

      if (ACTION == 'move') {
        this.moveAction(destinationNodeId)
        return
      }

      const copyMovePromises = this.selectedItems.map((item) => {
        const actionPromise = this[ACTION]({
          nodeId: item.node_id,
          params: { name: item.name, destination_id: destinationNodeId },
          parentId,
        });

        return actionPromise.then((response) => {
          if (response.data) {
            throw new Error(`Erro ${response.status}: ${response.statusText}`);
          }
        }).catch((error) => {
          console.error(`Erro ao ${actionType}:`, error)
          throw error
        });
      });

      Promise.all(copyMovePromises)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(`copy_successful`))
          this.$emit(`copy-success`)
        })
        .catch((error) => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t(`$copy_failed`))
          this.$emit(`copy-error`, error)
        })
    },

    moveAction(destinationNodeId) {
      let nodesIds = this.selectedItems.map(item => item.node_id)
      let names = this.selectedItems.map(item => item.name)

      let name = names[0]

      if (names.length > 1) {
        name = name + ' (+' + (names.length -1) + ')'
      }

      let params = {'entries': nodesIds, 'destination_id': destinationNodeId, 'name': name}

      this[ContentActions.MOVE_ENTRIES](params)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(`move_successful`))
          this.$emit(`move-success`)
        })
        .catch((error) => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t(`$move_failed`))
          this.$emit(`move-error`, error)
        })
    },

    removeAction() {
      this.$refs.modalRemove.openModal()
    },

    removeExecute() {
      this.$refs.modalRemove.closeModal()

      const removePromises = this.selectedItems.map((item) => {
        return this[ContentActions.REMOVE]({ nodeId: item.node_id, params: item })
          .catch((error) => {
            if (error.response) {
              const responseData = error.response.data
              this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('error ') + responseData.message)
            } else {
              this[AppActions.OPEN_APP_ERROR_MESSAGE](error.message)
            }
          });
      });

      Promise.all(removePromises)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('remove_successful'))
          this.$emit('remove-success')
        })
        .catch(() => {
          this.$emit('remove-error')
        })
    }

  }
};
</script>