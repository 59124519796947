<template>
  <div>
    <v-menu
      v-if="!item.only_path"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          small
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <ContentActionsPopup
        :data="item"
        :filter-type="item.type"
        @preview="openPreviewModal"
      />
    </v-menu>
    <span v-else>—</span>

    <PreviewModal ref="previewModal" :node="item" :file-icon="icon" />
  </div>
</template>

<script>

import IconsByMimetype from '@/utils/iconsByMimetype.js'

import ContentActionsPopup from '@/components/content/ContentActionsPopup'
import PreviewModal from '@/components/content/preview/PreviewModal'

export default {
  name: "ActionsColumn",

  components: {
    ContentActionsPopup,
    PreviewModal,
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    icon() {
      if (this.item.type == "folder") {
        return {icon: this.item.only_path ? 'mdi-folder-outline' : 'mdi-folder'}
      }

      return IconsByMimetype.getIconByMimetype(this.item.extension, this.item.mime)
    },
  },

  methods: {
    openPreviewModal() {
      this.$refs.previewModal.openModal()
    },
  },
}

</script>
